var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member_wrap"},[_c('div',{staticClass:"member_top"},[_vm._v(" 로그인/회원가입"),_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/sub/back_ico.png"),"alt":""}})])],1),_c('div',{staticClass:"member_box"},[_c('div',{staticClass:"mem_login"},[_c('ValidationObserver',{ref:"obServer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',{staticClass:"box_1"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
              },"name":"아이디"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userid),expression:"userid"}],attrs:{"type":"text","name":"userid","autocomplete":"off","maxlength":"20","placeholder":"아이디"},domProps:{"value":(_vm.userid)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.login.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.userid=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"box_2"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
              },"name":"비밀번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","name":"password","autocomplete":"off","maxlength":"20","placeholder":"비밀번호"},domProps:{"value":(_vm.password)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.login.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error-txt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"box_3"},[_c('a',{on:{"click":_vm.login}},[_vm._v("로그인")])])])]),_c('div',{staticClass:"box_4"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.autoLogin),expression:"autoLogin"}],attrs:{"type":"checkbox","name":"auto_logion"},domProps:{"checked":Array.isArray(_vm.autoLogin)?_vm._i(_vm.autoLogin,null)>-1:(_vm.autoLogin)},on:{"change":function($event){var $$a=_vm.autoLogin,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.autoLogin=$$a.concat([$$v]))}else{$$i>-1&&(_vm.autoLogin=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.autoLogin=$$c}}}}),_vm._v(" 로그인 상태 유지")])]),_c('ul',{staticClass:"box_5"},[_c('li',[_c('router-link',{attrs:{"to":"/member/find_id"}},[_vm._v("아이디 찾기")])],1),_c('li',[_c('router-link',{attrs:{"to":"/member/find_pw"}},[_vm._v("비밀번호 찾기")])],1)]),_c('div',{staticClass:"box_6"},[_c('router-link',{attrs:{"to":"/member/terms"}},[_vm._v("일반회원가입")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }