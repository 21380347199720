<template>
  <div class="member_wrap">
    <div class="member_top">
      로그인/회원가입<router-link to="/"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <div class="member_box">
      <div class="mem_login">
        <ValidationObserver ref="obServer">
          <form @submit.prevent="login">
            <div class="box_1">
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="아이디"
              >
                <input
                  type="text"
                  name="userid"
                  autocomplete="off"
                  v-model="userid"
                  @keypress.enter.prevent="login"
                  maxlength="20"
                  placeholder="아이디"
                />
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="box_2">
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="비밀번호"
              >
                <input
                  type="password"
                  name="password"
                  autocomplete="off"
                  @keypress.enter.prevent="login"
                  v-model="password"
                  maxlength="20"
                  placeholder="비밀번호"
                />
                <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="box_3">
              <a @click="login">로그인</a>
            </div>
          </form>
        </ValidationObserver>
        <div class="box_4">
          <label>
            <input type="checkbox" name="auto_logion" v-model="autoLogin" />
            로그인 상태 유지</label
          >
        </div>
        <ul class="box_5">
          <li><router-link to="/member/find_id">아이디 찾기</router-link></li>
          <li><router-link to="/member/find_pw">비밀번호 찾기</router-link></li>
        </ul>
        <div class="box_6">
          <router-link to="/member/terms">일반회원가입</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      userid: null,
      password: null,
      autoLogin: false,
    };
  },
  methods: {
    async login() {
      const validate = await this.$refs.obServer.validate();
      if (validate) {
        try {
          await this.$store.dispatch("member/login", {
            userid: this.userid,
            password: this.password,
            autoLogin: this.autoLogin,
          });
          if (this.isLogin) {
            //앱 채널에 로그인 했다고 알려줌
            setLoginSuccess();

            this.$toast.default(this.msg);
            //this.$alert(this.msg);
            if (this.$store.state.common.referer) {
              //마이페이지 비밀번호 인증 페이지가 referer 일경우 만 제외
              if (this.$store.state.common.referer.name !== "AuthCheck") {
                this.$router
                  .push(this.$store.state.common.referer.path)
                  .catch(() => {});

                //레퍼러 초기화
                this.$store.dispatch("common/setReferer", null);
                return false;
              }
              this.$router.push("/").catch(() => {});

              //레퍼러 초기화
              this.$store.dispatch("common/setReferer", null);
              return false;
            } else {
              this.$router.push("/").catch(() => {});
              return false;
            }
          } else {
            this.$toast.default(this.msg);
          }
        } catch (error) {
          console.log("Login Page error :", error);
        }
      }
    },
  },
  computed: {
    ...mapState("member", ["isLogin", "msg"]),
  },
};
</script>

<style></style>
